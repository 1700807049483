import { getRequest, postRequest, putRequest } from '../axios'

const { REACT_APP_API_URL } = process.env
const getUserBetHistory = ({ limit, pageNo, search, startDate, endDate, actionType }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-casino-transactions?limit=${limit}&pageNo=${pageNo}&search=${search}&startDate=${startDate}&endDate=${endDate}&actionType=${actionType}`)
const getUserCredential = (type) =>
  getRequest(`${REACT_APP_API_URL}/api/user/init-pay?type=${type}`)
const getWithdrawalHistory = ({ limit, pageNo, status, startDate, endDate, search }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-withdraw-requests?limit=${limit}&pageNo=${pageNo}&status=${status}&startDate=${startDate}&endDate=${endDate}&search=${search}`)
const cancelWithdrawRequest = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/user/cancel-withdraw-request`, data)
const getUserTransactions = ({ limit, pageNo, startDate, endDate, transactionType, search }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-user-transactions?limit=${limit}&pageNo=${pageNo}&startDate=${startDate}&endDate=${endDate}&transactionType=${transactionType}&search=${search}`)

const setWalletAction = ({ data }) =>
  postRequest(`${REACT_APP_API_URL}/api/user/wallet-action`, data)
const getWalletTransactions = ({ page, pageSize, action, currency = '', from, to }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/wallet-transaction?page=${page}&pageSize=${pageSize}&action=${action}&currency=${currency}&from=${from}&to=${to}`)
const setVaultAction = ({ data }) =>
  postRequest(`${REACT_APP_API_URL}/api/user/vault-action`, data)
const getVaultTransactions = ({ page, pageSize, action, from, to }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/vault-transactions?actionType=${action}&limit=${pageSize}&page=${page}&from=${from}&to=${to}`)
const getBonusTransaction = ({
  page,
  pageSize,
  action,
  from,
  to
}) =>
  getRequest(`${REACT_APP_API_URL}/api/user/user-bonus?bonusType=${action}&limit=${pageSize}&page=${page}&from=${from}&to=${to}`)
const getPromotionCashTransaction = ({ page, pageSize, action, currency = '', from, to }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/promotion-cash-transaction?page=${page}&pageSize=${pageSize}&action=${action}&from=${from}&to=${to}`)

export {
  getUserBetHistory,
  getUserCredential,
  getWithdrawalHistory,
  cancelWithdrawRequest,
  getUserTransactions,
  setWalletAction,
  getWalletTransactions,
  setVaultAction,
  getVaultTransactions,
  getBonusTransaction,
  getPromotionCashTransaction
}
