import { takeLatest, put } from 'redux-saga/effects'

import {
  getMyBets,
  getAllBets,
  getTenantLeaderBoard,
  getRollingContestList,
  getRollLeaderBoard,
  getEvents
} from '../../utils/apiCalls/bet'

import {
  getMyBetsStart,
  getMyBetsSuccess,
  getMyBetsFailure,
  getAllBetsStart,
  getAllBetsSuccess,
  getAllBetsFailure,
  getTenantLeaderBoardStart,
  getTenantLeaderBoardSuccess,
  getTenantLeaderBoardFailure,
  getRollingContestStatus,
  getRollingContestStatusSuccess,
  getRollingContestStatusFailure,
  getRollingContestStart,
  getRollingContestSuccess,
  getRollingContestFailure,
  getEventsStart,
  getEventsSuccess,
  getEventsFailure,
  getRecentBigWinsStart,
  getRecentBigWinsSuccess,
  getRecentBigWinsFailure
} from '../redux-slices/bets'

export default function * betsWatcher () {
  yield takeLatest(getMyBetsStart.type, getMyBetsWorker)
  yield takeLatest(getAllBetsStart.type, getAllBetsWorker)
  yield takeLatest(getTenantLeaderBoardStart.type, getTenantLeaderBoardWorker)
  yield takeLatest(getRecentBigWinsStart.type, getRecentBigWinsWorker)
  yield takeLatest(getRollingContestStatus.type, getRollingContestStatusWorker)
  yield takeLatest(getRollingContestStart.type, getRollingContestWorker)
  yield takeLatest(getEventsStart.type, getEventsWorker)
}

function * getEventsWorker (action) {
  try {
    const { data } = yield getEvents(action.payload)
    yield put(getEventsSuccess(data?.data?.eventCompetition))
  } catch (e) {
    yield put(getEventsFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getRollingContestWorker (action) {
  try {
    const { data } = yield getRollLeaderBoard(action.payload)
    yield put(getRollingContestSuccess(data?.data))
  } catch (e) {
    yield put(getRollingContestFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getTenantLeaderBoardWorker (action) {
  try {
    const { data } = yield getTenantLeaderBoard(action.payload)
    yield put(getTenantLeaderBoardSuccess(data?.data?.apiResponse))
  } catch (e) {
    yield put(getTenantLeaderBoardFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * getRecentBigWinsWorker (action) {
  try {
    const { data } = yield getTenantLeaderBoard(action.payload)
    yield put(getRecentBigWinsSuccess(data?.data?.apiResponse))
  } catch (e) {
    yield put(getRecentBigWinsFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getRollingContestStatusWorker (action) {
  const {
    limit,
    pageNo
  } = action.payload
  try {
    const { data } = yield getRollingContestList({
      limit,
      pageNo
    })
    yield put(getRollingContestStatusSuccess(data?.data?.rollingCompetition))
  } catch (e) {
    yield put(getRollingContestStatusFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * getMyBetsWorker (action) {
  try {
    const { data } = yield getMyBets(action.payload)
    yield put(getMyBetsSuccess(data?.data))
  } catch (e) {
    yield put(getMyBetsFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * getAllBetsWorker (action) {
  try {
    const { data } = yield getAllBets(action.payload)
    yield put(getAllBetsSuccess(data?.data))
  } catch (e) {
    yield put(getAllBetsFailure(e?.response?.data?.errors[0]?.description))
  }
}
