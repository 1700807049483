import axios from 'axios'
import { getRequest, postRequest, putRequest } from '../axios'
import { getRefreshToken } from '../storageUtils'

const { REACT_APP_API_URL } = process.env
const userLogin = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/user/login`, data)

const getRegistrationFields = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-registration-fields`)
const userSignUp = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/user/sign-up`, data)
const getTenantDetails = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-tenant-detail`)
const getAllCountries = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-all-country`)
const verifyEmail = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/user/verify-email`, data)
const resendEmail = (email) =>
  postRequest(`${REACT_APP_API_URL}/api/user/refresh-email-token`, { email })
const forgetPassword = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/user/forget-password`, data)
const resetPassword = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/user/update-password`, data)
const logout = () =>
  postRequest(`${REACT_APP_API_URL}/api/user/logout`)
const getThemes = () =>
  getRequest(`${REACT_APP_API_URL}/api/user/themes`)
const verifyUserPassword = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/user/verify-withdrawl-password`, data)
const checkRefreshToken = (data) =>
  axios.post(`${REACT_APP_API_URL}/api/user/refresh-token`, {}, {
    headers: {
      'Content-Type': 'application/json',
      refreshToken: getRefreshToken()
    }
  }
  )
const forgetPasswordRequest = (username) =>
  putRequest(`${REACT_APP_API_URL}/api/user/request-forget-password`, username)

const getBetByTokenAPIEndPoint = () => getRequest(`${REACT_APP_API_URL}/api/betby/get-token`)

export {
  userLogin,
  getRegistrationFields,
  userSignUp,
  getTenantDetails,
  getAllCountries,
  verifyEmail,
  resendEmail,
  forgetPassword,
  resetPassword,
  logout,
  getThemes,
  verifyUserPassword,
  checkRefreshToken,
  forgetPasswordRequest,
  getBetByTokenAPIEndPoint
}
