export const SPORTS_LIMIT = 12
export const BET_AMOUNT_ARRAY = [1000, 5000, 10000]
export const BET_AMOUNT_ARRAY_TOP_MATCH = [200, 500, 1000, 5000]
export const BET_AMOUNT_ARRAY_HOT_COMBO = [200, 500, 1000, 2000, 5000]
export const EVENT_LISTENERS = {
  ROLL_EMITTER: 'ROLL_EMITTER',
  DISCONNECT: 'disconnect',
  CONNECT: 'connect',
  ERROR: 'error',
  USER_WALLET_BALANCE: 'USER_WALLET_BALANCE',
  ADMIN_USER_WALLET_BALANCE: 'ADMIN_USER_WALLET_BALANCE',
  LIVE_PLAYER_COUNT: 'LIVE_PLAYER_COUNT',
  NOTIFICATION: 'NOTIFICATION',
  UNIQUE_NUMBER_BONUS: 'UNIQUE_NUMBER_BONUS',
  WALLET_NOTIFICATION: 'WALLET_NOTIFICATION',
  PLAYER_LEVEL: 'PLAYER_LEVEL',
  VOUCHER_EMITTER: 'VOUCHER_EMITTER',
  BETRADAR_ODDS_CHANGE: 'BETRADAR_ODDS_CHANGE',
  BETRADAR_FIXTURE_CHANGE: 'betradarFixtureChange',
  BETRADAR_BET_SETTLEMENT: 'BETRADAR_BET_SETTLEMENT',
  BETRADAR_ROLLBACK_BET_SETTLEMENT: 'BETRADAR_ROLLBACK_BET_SETTLEMENT',
  BETRADAR_BET_UPDATES: 'BETRADAR_BET_UPDATE',
  BETRADAR_BET_STOP: 'BETRADAR_BET_STOP',
  NEW_EVENT: 'NEW_EVENT',
  BONUS_NOTIFICATION: 'BONUS_NOTIFICATION',
  BETRADAR_CONNECTION_STATUS: 'BETRADAR_CONNECTION_STATUS',
  USER_SESSION_ACTIVITY: 'USER_SESSION_ACTIVITY'
}

export const gammastackDemoURL = [
  'https://staging.d3a5rsc2g45so8.amplifyapp.com/',
  'tenant-first-staging.dinocasino.com'
]

export const isGamma = window.location.origin.includes('staging.d3a5rsc2g45so8.amplifyapp.com')
export const BONUS_TRANSACTION_DETAILS = {
  'HOT-TIME-BONUS-DEPOSIT': 'hotTimeBonus',
  'COMPENSATION-BONUS-DEPOSIT': 'compensationBonus',
  'REOCCURING-BONUS-DEPOSIT': 'reoccuringBonus',
  'FIRST-DEPOSIT-BONUS-DEPOSIT': 'firstDepositBonus'
}
export const BONUSES = {
  hotTimeBonus: 'HOT-TIME-BONUS',
  reOccuringDepositBonus: 'RE-OCCURING-DEPOSIT-BONUS',
  firstDepositBonus: 'FIRST-DEPOSIT-BONUS'
  // paybackBonus: 'PAYBACK-BONUS'
}
export const ALL_BONUSES = {
  hotTimeBonus: 'HOT-TIME-BONUS',
  reOccuringDepositBonus: 'RE-OCCURING-DEPOSIT-BONUS',
  firstDepositBonus: 'FIRST-DEPOSIT-BONUS',
  paybackBonus: 'PAYBACK-BONUS',
  compensationBonus: 'COMPENSATION-BONUS'
}
export const BET_TYPE = {
  SINGLE: {
    name: 'single',
    value: '1'
  },
  COMBO: {
    name: 'combo',
    value: '2'
  },
  CUSTOM: {
    name: 'customBet',
    value: '3'
  }
}
export const SPORTS_HOME_TABS = {
  LIVE: 'live',
  UPCOMING: 'upcoming',
  TOP: 'top'
}

export const FAV_TABS = {
  LIVE: 'live',
  UPCOMING: 'upcoming'
}

export const SOCKET_ROOM_NAME = {
  ROLL_BONUS: 'roll-bonus',
  WALLET: 'wallet',
  ADMIN_WALLET: 'admin-wallet',
  LIVE_PLAYERS: 'live-players',
  NOTIFICATION: 'notification',
  UNIQUE_NUMBER_BONUS_WINNER: 'unique-number-bonus-winner',
  WALLET_NOTIFICATION: 'wallet_notification',
  PLAYER_LEVEL: 'player-level',
  VOUCHER: 'voucher',
  BETRADAR_SPORT_UPDATES: 'betradar-sport-updates',
  BETRADAR_BET_UPDATES: 'betradar-bet-updates',
  NEW_EVENT: 'new-event',
  BONUS_NOTIFICATION: 'bonus_notification',
  BETRADAR_FIXTURE_UPDATES: '',
  USER_ACTIVITY: 'user-activity'
}
// Note please don't change the keys order of Theme object.
export const THEMES = {
  LIGHT: 'light',
  DARK: 'dark'
  // SYSTEM: 'system',
  // CUSTOM: 'custom'
}

export const NOTIFICATION_REFERENCES = {
  a: 'EMERGENCY',
  b: 'MAINTENANCE',
  c: 'Bonus',
  d: 'WALLET',
  e: 'UNIQUE-NUMBER-BONUS',
  f: 'SportsBook',
  g: 'VOUCHER',
  h: 'DEPOSIT',
  i: 'WITHDRAWAL'
}

export const NOTIFICATION_ICONS = {
  [NOTIFICATION_REFERENCES.a]: '/icon/emergency.png',
  [NOTIFICATION_REFERENCES.b]: '/icon/maintenance.png',
  [NOTIFICATION_REFERENCES.c]: '/icon/bonus.png',
  [NOTIFICATION_REFERENCES.h]: '/icon/wallet.png',
  [NOTIFICATION_REFERENCES.i]: '/icon/wallet.png',
  [NOTIFICATION_REFERENCES.d]: '/icon/wallet.png',
  [NOTIFICATION_REFERENCES.e]: '/icon/bonus.png',
  [NOTIFICATION_REFERENCES.f]: '/icon/bonus.png',
  [NOTIFICATION_REFERENCES.g]: '/icon/coupon.png'
}

export const initialLang = 'English'
export const initialLangCode = 'EN'

// export const initialLang = 'Korean'
// export const initialLangCode = 'KO'

export const TRANSACTIONS_VAULT_TYPE = {
  WALLET: 'WALLET',
  VAULT: 'VAULT',
  BONUS: 'BONUS',
  PROMOTION_CASH: 'PROMOTION_CASH'
}

export const WALLET_ACTION = {
  DEPOSIT: 'DEPOSIT',
  WITHDRAW: 'WITHDRAW'
}
export const VAULT_ACTION_TYPE = {
  DEPOSIT: 'VAULT-DEPOSIT',
  WITHDRAW: 'VAULT-WITHDRAW'
}
export const TRANSACTIONS_TAB_MAP = {
  1: 'DEPOSIT',
  2: 'WITHDRAW'
}
export const BONUS_TAB_MAP = {
  1: 'hot_time_bonus',
  2: 're_occurring_bonus',
  3: 'compensation_bonus',
  4: 'first_deposit_bonus'
}
export const LANGUAGE_LIST = {
  EN: 'English',
  KO: '한국어',
  JA: '日本'
}
export const KYC_STATUS = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED'
}

export const SORT = {
  ASC: 'asc',
  DESC: 'desc'
}

export const ICONS = {
  3: '/icon/Blackjack.svg',
  6: '/icon/Roulette.svg',
  2: '/icon/Slot.svg',
  4: '/icon/Live-Games.svg',
  5: '/icon/Baccarat.svg',
  1: '/icon/Scratchcard.svg',
  8: '/icon/fire.svg',
  9: '/icon/new.svg',
  10: '/icon/gamble.svg',
  11: '/icon/poker.svg'
}

export const USER_RANKS = {
  1: '/images/level/bronze.png',
  2: '/images/level/silver.png',
  3: '/images/level/gold.png',
  4: '/images/level/platinum1.png',
  5: '/images/level/platinum2.png',
  6: '/images/level/platinum3.png',
  7: '/images/level/platinum4.png',
  8: '/images/level/platinum5.png',
  9: '/images/level/platinum6.png',
  10: '/images/level/diamond1.png',
  11: '/images/level/diamond2.png',
  12: '/images/level/diamond3.png',
  13: '/images/level/diamond4.png'
}

export const scheduledTime = [
  {
    sportTime: 'all',
    value: 'all'
  },
  {
    sportTime: '1H',
    value: 1
  },
  {
    sportTime: '2HS',
    value: 2
  },
  {
    sportTime: '3HS',
    value: 3
  },
  {
    sportTime: '6HS',
    value: 6
  },
  {
    sportTime: '12HS',
    value: 12
  },
  {
    sportTime: '24HS',
    value: 24
  },
  {
    sportTime: '48HS',
    value: 48
  }
]

export const SOCKET_EMMITER = {
  ODDS_CHANGE: 'oddsChange',
  FIXTURE_CHANGE: 'fixtureChange',
  BET_SETTLEMENT: 'betSettlement',
  BET_STOP: 'betStop',
  ROLLBACK_BET_SETTLEMENT: 'rollbackBetSettlement',
  JOIN: 'join',
  LEAVE: 'leave',
  SESSION_ROOMS: 'joindRooms',
  CONNECTION_STATUS: 'connectionStatus',
  OUTCOME_PLAYER_NAME: 'outcomePlayerName'
}
export const BONUS_KEYS = {
  'WINNING-BONUS': '/images/bonus/winningBonus.png',
  'BETTING-BONUS': '/images/bonus/betBonus.png',
  'MIXED-MATCH': '/images/bonus/mixedBonus.png',
  'BET-COMPENSATION': '/images/bonus/compensationBonus.png',
  'COMPENSATION-BONUS': '/images/bonus/compensationBonus.png'
}
export const MILLISECONDS = 1800000 // 30 minutes in milliseconds
export const USER_BONUS_TYPE_FILTER = {
  hot_time_bonus: 'HOT-TIME-BONUS',
  compensation_bonus: 'COMPENSATION-BONUS',
  re_occurring_bonus: 'RE-OCCURING-DEPOSIT-BONUS',
  first_deposit_bonus: 'FIRST-DEPOSIT-BONUS',
  unique_ticket_bonus: 'UNIQUE-TICKET-BONUS',
  rackback_bonus: 'RACKBACK-BONUS',
  daily_periodic_payback_banus: 'DAILY-PERIODIC-PAYBACK-BONUS',
  bi_weekly_periodic_payback_bonus: 'BI-WEEKLY-PERIODIC-PAYBACK-BONUS',
  weekly_periodic_payback_bonus: 'WEEKLY-PERIODIC-PAYBACK-BONUS',
  monthly_periodic_payback_bonus: 'MONTHLY-PERIODIC-PAYBACK-BONUS'
}

export const UPDATED_USER_BONUS_TYPE_FILTER = {
  compensation_bonus: 'COMPENSATION-BONUS',
  rackback_bonus: 'RACKBACK-BONUS',
  daily_periodic_payback_banus: 'DAILY-PERIODIC-PAYBACK-BONUS',
  bi_weekly_periodic_payback_bonus: 'BI-WEEKLY-PERIODIC-PAYBACK-BONUS',
  weekly_periodic_payback_bonus: 'WEEKLY-PERIODIC-PAYBACK-BONUS',
  monthly_periodic_payback_bonus: 'MONTHLY-PERIODIC-PAYBACK-BONUS'
}

export const USER_BONUS_TYPE_VALUES = {
  HotTimeBonusAmount: 'HOT-TIME-BONUS',
  CompensationBonusAmount: 'COMPENSATION-BONUS',
  ReOccuringDepositBonusAmount: 'RE-OCCURING-DEPOSIT-BONUS',
  FirstDepositBonusAmount: 'FIRST-DEPOSIT-BONUS',
  UniqueTicketBonusAmount: 'UNIQUE-TICKET-BONUS',
  RackbackBonusAmount: 'RACKBACK-BONUS',
  DailyPeriodicPaybackBonusAmount: 'DAILY-PERIODIC-PAYBACK-BONUS',
  BiWeeklyPeriodicPaybackBonusAmount: 'BI-WEEKLY-PERIODIC-PAYBACK-BONUS',
  WeeklyPeriodicPaybackBonusAmount: 'WEEKLY-PERIODIC-PAYBACK-BONUS',
  MonthlyPeriodicPaybackBonusAmount: 'MONTHLY-PERIODIC-PAYBACK-BONUS'
}
export const ROLLING_CONSTANT = {
  depositRolling: 'Deposit Rolling',
  promotionCashRolling: 'Promotion Cash Rolling',
  bonusRolling: 'Bonus Rolling',
  vouchersRolling: 'Vouchers Rolling',
  giftCardsRolling: 'Gift Cards Rolling',
  seasonsRolling: 'Seasons Rolling'
}
export const ROLLING_TRANSLATION_KEY = {
  'Deposit Rolling': 'depositRolling',
  'Promotion Cash Rolling': 'promotionCashRolling',
  'Bonus Rolling': 'bonusRolling',
  'Vouchers Rolling': 'vouchersRolling',
  'Gift Cards Rolling': 'giftCardsRolling',
  'Seasons Rolling': 'seasonsRolling'
}
export const ALLPROVIDERS = {
  1: 'hacksaw',
  2: 'pragmatic',
  3: 'evolution',
  4: 'pgsoft',
  5: 'redTiger',
  6: 'netEnt',
  7: 'bigTimeGaming',
  8: 'nolimitCity',
  9: 'habanero',
  10: 'extendednetent',
  11: 'ezugi',
  12: 'AWC',
  13: 'relax',
  14: 'BetterLive',
  15: 'MG',
  16: 'PopOk',
  17: 'SAGaming',
  18: 'Winfinity',
  19: 'Bgaming',
  20: 'CreedRomz',
  21: 'ImagineLive'
}
export const KRW_SYMBOL = '₩'

export const SCOREBOARD_TABS = {
  MYBETS: 'my-bets',
  ALLBETS: 'all-bets',
  RACE_LEADERBORAD: 'race-leaderboard',
  SPORTS_BETS: 'sports-bets',
  CASINO_BETS: 'casino-bets',
  ROLLING_CONTEST: 'rolling-contest'
}
