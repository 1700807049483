import { takeLatest, put, select, delay, all, call } from 'redux-saga/effects'
import {
  getGameCategory,
  getGameSubCategory,
  getCasinoGames,
  getProviders,
  addFavoriteGame,
  removeFavoriteGame,
  getFavoriteGame,
  getTopGames,
  getTopWinners,
  getCurrentWinners,
  getCasinoProvider,
  playGame,
  getHotGames,
  getNewGames,
  getTopSlotsGames,
  getNewGamesLobby,
  getHotGamesLobby
} from '../../utils/apiCalls/casinoMenu'
import {
  getHotGameSearchGamesStart,
  getHotGameSearchGamesSuccess,
  getHotGameSearchFaliure,
  updateHomeGameDataStart,
  updateHomeGameDataComplete,
  updateHomeGameDataFailure,
  getGameSubCategoryStart,
  getGameSubCategorySuccess,
  getGameSubCategoryFailure,
  getGameStart,
  getGameSuccess,
  getGameFailure,
  getFavGameStart,
  getFavGameSuccess,
  getFavGameFailure,
  getGameCategoryStart,
  getGameCategorySuccess,
  getGameCategoryFailure,
  getHomeDataStart,
  getHomeDataSuccess,
  getHomeDataFailure,
  getProvidersStart,
  getProvidersSuccess,
  getProvidersFailure,
  toggleFavoriteStart,
  toggleFavoriteFailure,
  getFilteredGamesStart,
  getFilteredGamesSuccess,
  getFilteredGamesFailure,
  removeFavoriteStart,
  removeFavoriteSuccess,
  removeFavoriteFailure,
  getTopGamesStart,
  getTopGamesSuccess,
  getTopGamesFailure,
  getTopWinnersStart,
  getTopWinnersSuccess,
  getTopWinnersFailure,
  getCurrentWinnersStart,
  getCurrentWinnersSuccess,
  getCurrentWinnersFailure,
  getCategoryProviderListStart,
  getCategoryProviderListSuccess,
  getCategoryProviderListFailure,
  getTopSlotsGamesStart,
  getTopSlotsGamesSuccess,
  getTopSlotsGamesFailure,
  getPlayGameSuccess,
  getPlayGameFailure,
  getPlayGameStart,
  getRecommendedGamesSuccess,
  getRecommendedGamesFailure,
  getRecommendedGamesStart,
  setMappedCategory,
  getHotGameStart,
  getNewGameStart
} from '../redux-slices/casinoMenu'

import { getLanguageDataStart } from '../redux-slices/language'
import { showFooter, showLeagueList, showRealPlayer } from '../redux-slices/loader'
import { getLanguage } from '../../utils/storageUtils'
import { toast } from '../../components/Toast'
import { t } from 'i18next'
import { Routes } from '../../utils/routes'
const getPlayGame = (state) => state.casinoMenu.playGame
const getFavGames = (state) => state.casinoMenu.favoriteGamesList

export default function * casinoMenuWatcher () {
  yield takeLatest(getRecommendedGamesStart.type, getRecommendedGamesWorker)
  yield takeLatest(getGameCategoryStart.type, getGameCategoryWorker)
  yield takeLatest(getPlayGameStart.type, getPlayGameWorker)
  yield takeLatest(getTopSlotsGamesStart.type, getTopSlotsGamesWorker)
  yield takeLatest(getGameSubCategoryStart.type, getGameSubCategoryWorker)
  yield takeLatest(getGameStart.type, getGameWorker)
  yield takeLatest(getHotGameStart.type, getHotGameStartWorker)
  yield takeLatest(getHotGameSearchGamesStart.type, getHotGameSearchGamesWorker)
  yield takeLatest(getNewGameStart.type, getNewGameStartWorker)
  yield takeLatest(getFavGameStart.type, getFavGameWorker)
  yield takeLatest(getHomeDataStart.type, getHomeDataWorker)
  yield takeLatest(getProvidersStart.type, getProvidersWorker)
  yield takeLatest(toggleFavoriteStart.type, toggleFavoriteWorker)
  yield takeLatest(removeFavoriteStart.type, removeFavoriteWorker)
  yield takeLatest(getFilteredGamesStart.type, getFilteredGamesWorker)
  yield takeLatest(getTopGamesStart.type, getTopGamesWorker)
  yield takeLatest(getTopWinnersStart.type, getTopWinnersWorker)
  yield takeLatest(getCurrentWinnersStart.type, getCurrentWinnersWorker)
  yield takeLatest(updateHomeGameDataStart.type, updateHomeGameDataStartWorker)
  yield takeLatest(getCategoryProviderListStart.type, getCasinoProviderWorker)
}

function * getGameCategoryWorker () {
  try {
    const { data } = yield getGameCategory()
    const tempData = [...data?.data?.gameCategories]
    const tempArr = ['live games', 'hot', 'new', 'others']
    const topGames = []
    const otherGames = []
    tempData.forEach(item => {
      if (tempArr.includes(item.name)) {
        topGames.push(item)
      } else {
        otherGames.push(item)
      }
    })
    const liveGame = topGames?.find(item => item.name === tempArr[0])
    const hot = topGames?.find(item => item.name === tempArr[1])
    const newGame = topGames?.find(item => item.name === tempArr[2])
    const others = topGames?.find(item => item.name === tempArr[3])
    const sortedData = [liveGame, hot, newGame, ...otherGames, others].filter(item => item !== undefined && item !== null)
    const mappedData = {}
    sortedData.forEach((item) => {
      mappedData[item.id] = { ...item }
    })
    let newHot = {}
    if (hot) {
      newHot = {
        [hot.id]: hot
      }
    }
    if (newGame) {
      newHot = {
        ...newHot,
        [newGame.id]: newGame
      }
    }
    yield put(getGameCategorySuccess({ gameCategories: sortedData, newHot }))
    yield put(setMappedCategory(mappedData))
  } catch (e) {
    yield put(getGameCategoryFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getGameSubCategoryWorker (action) {
  try {
    const { categoryId } = action && action.payload
    const { data } = yield getGameSubCategory({ categoryId })

    yield put(getGameSubCategorySuccess(data?.data?.response))
    yield put(showLeagueList())
    yield put(showRealPlayer())
  } catch (e) {
    yield put(getGameSubCategoryFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getGameWorker (action) {
  try {
    const { page } = action && action.payload
    const { data } = yield getCasinoGames(action.payload)
    yield put(getGameSuccess({ ...data?.data, page }))
  } catch (e) {
    yield put(getGameFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * getHotGameStartWorker (action) {
  try {
    const { pageNo } = action && action.payload
    const { data } = yield getHotGames({ ...action.payload, languageCode: getLanguage() })
    yield put(getGameSuccess({
      ...data?.data,
      casinoGameListing: data?.data?.hotGames,
      page: pageNo
    }))
  } catch (e) {
    yield put(getGameFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * getHotGameSearchGamesWorker (action) {
  try {
    const { pageNo } = action && action.payload
    const { data } = yield getHotGames({ ...action.payload, languageCode: getLanguage() })
    let tempData
    if (pageNo === 1) {
      tempData = {
        casinoGameListing: data?.data?.hotGames,
        pageInfo: data?.data?.pageInfo
      }
    } else {
      const { hotSearchedGames } = yield select((state) => state?.casinoMenu)
      tempData = {
        pageInfo: data?.data?.pageInfo,
        casinoGameListing: [...hotSearchedGames?.casinoGameListing, ...data?.data?.hotGames]
      }
    }
    yield put(getHotGameSearchGamesSuccess(tempData))
  } catch (e) {
    yield put(getHotGameSearchFaliure(e?.response?.data?.errors[0]?.description))
  }
}
function * getNewGameStartWorker (action) {
  try {
    const { pageNo } = action && action.payload
    const { data } = yield getNewGames({ ...action.payload, languageCode: getLanguage() })
    yield put(getGameSuccess({
      ...data?.data,
      casinoGameListing: data?.data?.newGames || [],
      page: pageNo
    }))
  } catch (e) {
    yield put(getGameFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * getRecommendedGamesWorker (action) {
  try {
    const { page, pageSize, sortField, sortOrder, category, userID, languageCode, recommended } = action && action.payload
    yield delay(100)
    const { data } = yield getNewGamesLobby({
      page, pageSize, sortField, sortOrder, category, userID, languageCode, recommended
    })
    yield put(getRecommendedGamesSuccess(data?.data))
  } catch (e) {
    yield put(getRecommendedGamesFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * getPlayGameWorker (action) {
  try {
    const gameData = action && action.payload
    const { data } = yield playGame({ ...gameData })
    yield put(getPlayGameSuccess(data?.data?.data))
  } catch (e) {
    yield put(getPlayGameFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * getTopSlotsGamesWorker (action) {
  try {
    const { window } = action.payload
    const { pathname } = window.location
    const isMobile = window?.screen?.width < 768

    let topGames = {}
    if ((pathname === Routes?.homepage && isMobile) || (pathname === Routes.casino)) {
      const { data } = yield getTopSlotsGames()
      const groupedData = Object.groupBy(data?.data?.casinoGameListing, ({ categoryGameId }) => categoryGameId)
      topGames = { ...groupedData }
    }
    if ((pathname === Routes?.homepage && !isMobile)) {
      const { mappedCategory } = yield select(state => state.casinoMenu)
      const { gameCategory } = yield select(state => state.casinoMenu)
      const newHotArr = Object.keys(gameCategory?.newHot || {})
      const tempArr = []
      Object.keys(gameCategory?.newHot)?.forEach((key) => {
        if (gameCategory?.newHot[key]?.name === 'hot') {
          tempArr.push(call(getHotGamesLobby, {
            gameName: '', limit: 10, pageNo: 1
          }))
        } else if (gameCategory?.newHot[key]?.name === 'new') {
          tempArr.push(call(getNewGamesLobby, {
            gameName: '', limit: 10, pageNo: 1
          }))
        }
      })
      const res = yield all(tempArr)
      newHotArr.forEach((key, index) => {
        const { data } = res[index]
        if (mappedCategory[key]?.name === 'hot') {
          topGames[mappedCategory[key]?.id] = data?.data.hotGames
        } else if (mappedCategory[key]?.name === 'new') {
          topGames[mappedCategory[key]?.id] = data?.data.newGames
        }
      })
    }

    yield put(getTopSlotsGamesSuccess(topGames))
  } catch (e) {
    yield put(getTopSlotsGamesFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * updateHomeGameDataStartWorker (action) {
  try {
    const { sortOrder, categoryId } = action && action.payload
    const params = {
      page: 1,
      sortField: 'name',
      sortOrder: sortOrder,
      pageSize: 10,
      userID: null,
      category: categoryId,
      languageCode: getLanguage()
    }

    const { data } = yield getCasinoGames({
      ...params
    })
    yield put(updateHomeGameDataComplete({ id: categoryId, data: data?.data?.casinoGameListing }))
  } catch (e) {
    yield put(updateHomeGameDataFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getFavGameWorker (action) {
  try {
    const { pageNo, limit } = action && action.payload
    const { data } = yield getFavoriteGame({ limit, pageNo })
    yield put(getFavGameSuccess(data?.data))
  } catch (e) {
    yield put(getFavGameFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getHomeDataWorker (action) {
  try {
    const { getLang = true, navigate } = action && action.payload
    if (getLang) yield put(getLanguageDataStart({ navigate }))

    const { data: gameCategory } = yield getGameCategory()

    yield put(getHomeDataSuccess({
      gameCategory: gameCategory?.data
    }))
  } catch (e) {
    const { navigate } = action?.payload

    if (e?.response?.data?.errors[0]?.errorCode === 3000) {
      navigate && navigate(`/${getLanguage()?.toLowerCase() || 'en'}/600`)
    }

    yield put(getHomeDataFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getProvidersWorker (action) {
  const categoryId = action?.payload?.categoryId || ''; const showCount = action?.payload?.showCount

  try {
    const { data } = yield getProviders({ categoryId, showCount })
    yield put(getProvidersSuccess(data?.data?.providerList))
  } catch (e) {
    yield put(getProvidersFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * toggleFavoriteWorker (action) {
  try {
    const {
      isFavourite, masterCasinoGameId, setPageNo, gameIdentifier,
      masterCasinoProviderId, masterCasinoGameSubCategory, masterCasinoGameName
    } = action && action.payload

    const apiPayload = {
      masterCasinoGameId,
      gameIdentifier,
      masterCasinoProviderId,
      masterCasinoGameSubCategory,
      masterCasinoGameName
    }
    const playGame = yield select(getPlayGame)
    const getFavGamesList = yield select(getFavGames)
    const { favoritesGames } = getFavGamesList
    // const {currentPage, pageSize,totalCount,totalPages}=pageInfo

    if (isFavourite) {
      const { data } = yield removeFavoriteGame(apiPayload)
      if (data?.data?.success === true) {
        yield put(getPlayGameSuccess({ ...playGame, isFavourite: !isFavourite }))
        if (setPageNo) {
          if (favoritesGames?.length > 0) {
            const filteredGamesList = favoritesGames?.filter(item => item.masterCasinoGameId !== masterCasinoGameId)
            const tempData = {
              ...getFavGamesList,
              favoritesGames: filteredGamesList

            }
            yield put(getFavGameSuccess({ ...tempData, isToggle: true }))
            yield put(getFavGameStart({ limit: 10, pageNo: 1 }))
            setPageNo && setPageNo(1)
          }
        }
        yield toast(`${t('removeFromFavSuccess')}`, 'success')
      }
    } else {
      const { data } = yield addFavoriteGame(apiPayload)
      if (data?.data?.success === true) {
        yield put(getPlayGameSuccess({ ...playGame, isFavourite: !isFavourite }))
        yield toast(`${t('addToFavSuccess')}`, 'success')
      }
    }
  } catch (e) {
    yield put(toggleFavoriteFailure(e?.response?.data?.errors[0]?.description))
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * removeFavoriteWorker (action) {
  try {
    const { isFavorite, categoryGameId } = action && action.payload
    let updatedGames = yield select(getFavGames)
    let updatedGameRows = updatedGames?.rows
    if (isFavorite) {
      yield removeFavoriteGame({ categoryGameId })
      updatedGameRows = yield updatedGameRows.filter((game) => game.categoryGameId !== categoryGameId)
      updatedGames = yield { ...updatedGames, rows: updatedGameRows }
    }
    yield put(removeFavoriteSuccess(updatedGames))
  } catch (e) {
    yield put(removeFavoriteFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getFilteredGamesWorker (action) {
  const { gameCategory } = yield select((state) => state?.casinoMenu)
  const { newHot } = gameCategory && gameCategory

  // newHot[categoryName]?.name === 'hot'
  try {
    const { page, pageSize, sortField, sortOrder, category = '', searchKey = '', userID = null, masterCasinoProviderId = '', languageCode } = action && action.payload
    yield delay(10)
    if (newHot[category]?.name === 'hot') {
      const { data } = yield getHotGames({ gameName: searchKey, limit: pageSize, pageNo: page, languageCode: getLanguage() })
      yield put(getFilteredGamesSuccess({ ...data?.data, casinoGameListing: data?.data?.hotGames }))
    } else if ((newHot[category]?.name === 'new')) {
      const { data } = yield getNewGames({ gameName: searchKey, limit: pageSize, pageNo: page, languageCode: getLanguage() })
      yield put(getFilteredGamesSuccess({ ...data?.data, casinoGameListing: data?.data?.newGames }))
    } else {
      const { data } = yield getCasinoGames({
        page, pageSize, sortField, sortOrder, category, searchKey, userID, masterCasinoProviderId, languageCode
      })
      yield put(getFilteredGamesSuccess(data?.data))
    }
  } catch (e) {
    yield put(getFilteredGamesFailure(e?.response?.data?.errors[0]?.description))
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
  }
}

function * getTopGamesWorker (action) {
  try {
    const { limit } = action && action.payload

    const { data: res } = yield getTopGames({ limit })

    yield put(getTopGamesSuccess(res?.data?.gameReport))

    yield put(getTopWinnersStart({ limit: 5 }))

    yield put(getCurrentWinnersStart({ limit: 20 }))

    yield put(showFooter(true))
  } catch (e) {
    yield put(getTopGamesFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getTopWinnersWorker (action) {
  try {
    const { limit } = action && action.payload

    const { data: res } = yield getTopWinners({ limit })

    yield put(getTopWinnersSuccess(res?.data?.topWinners))
  } catch (e) {
    yield put(getTopWinnersFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getCurrentWinnersWorker (action) {
  try {
    const { limit } = action && action.payload

    const { data: res } = yield getCurrentWinners({ limit })

    yield put(getCurrentWinnersSuccess(res?.data?.currentWinners))
  } catch (e) {
    yield put(getCurrentWinnersFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getCasinoProviderWorker (action) {
  try {
    const { category, search } = action && action.payload

    const { data: res } = yield getCasinoProvider({ category, search })

    yield put(getCategoryProviderListSuccess(res?.data?.providerList))
  } catch (e) {
    yield put(getCategoryProviderListFailure(e?.response?.data?.errors[0]?.description))
  }
}
