import { getRequest, postRequest, putRequest, deleteRequest } from '../axios'
import { getLanguage } from '../storageUtils'

const { REACT_APP_API_URL } = process.env
const getDocuments = () =>
  getRequest(`${REACT_APP_API_URL}/api/document/get-documents`)
const getDocumentsLabel = () =>
  getRequest(`${REACT_APP_API_URL}/api/document/get-document-label`)
const getUserDetails = (source) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-user-details?source=${source || ''}`)
const removeProfileImage = () =>
  putRequest(`${REACT_APP_API_URL}/api/user/remove-profile-image`)
const updateDocuments = ({ data }) =>
  putRequest(`${REACT_APP_API_URL}/api/document/update-user-document`, data)
const updateUserDetails = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/user/update-user-details`, data)
const uploadProfileImage = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/user/upload-profile-image`, data)
const getSumSubAccessToken = () =>
  getRequest(`${REACT_APP_API_URL}/api/document/init-access-token`)
const getAllReviews = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-reviews`)
const getCashbackBalance = () =>
  getRequest(`${REACT_APP_API_URL}/api/user/cashback-balance`)
const getWalletAmount = () =>
  getRequest(`${REACT_APP_API_URL}/api/user/wallet-amount`)
const addBrowserId = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/user/insert-unique-key`, data)

const getAllCurrencies = () =>
  getRequest(`${REACT_APP_API_URL}/api/user/currency-list`)
const getReferredUsersList = ({ page, pageSize }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/invited-users?page=${page}&pageSize=${pageSize}`)
const getNotifications = ({ limit, pageNo, isRead }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/unread-notification-count?limit=${limit}&pageNo=${pageNo}&isRead=${isRead}`)
const readNotification = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/user/read-notification`, data)

const getBonusTickets = ({ limit, pageNo }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/user-unique-tickets?limit=${limit}&pageNo=${pageNo}`)
const rollBonus = () =>
  postRequest(`${REACT_APP_API_URL}/api/user/bonus-roll`)
const getRanks = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-ranks?language=${getLanguage()}`)
const getSeasonRanks = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-season-ranks?language=${getLanguage('language')}`)
const addUserToRollingContest = (user) =>
  postRequest(`${REACT_APP_API_URL}/api/user/user-competition`, user)
const getBonusWalletProgress = ({ bonusType }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-user-bonus-wallet?bonusType=${bonusType}`)

const getUniqueCodes = (formData) => {
  const urlString = new URLSearchParams(formData).toString()
  return getRequest(`${REACT_APP_API_URL}/api/unique-code-bonus/all?${urlString}`)
}
const getAddOnConfigDetails = (formData) => {
  const urlString = new URLSearchParams(formData).toString()
  return getRequest(`${REACT_APP_API_URL}/api/user/deposit-addons-progress?${urlString}`)
}

const getAddOnConfigBonusDetails = (action) => {
  const urlString = new URLSearchParams(action?.payload?.payload).toString()
  return getRequest(`${REACT_APP_API_URL}/api/user/${action?.payload?.type === 'PROMOTION' ? 'promotion-rolling-progress' : 'bonus-wagering-progress'}?${urlString}`)
}

const updateUserLanguage = (formData) => putRequest(`${REACT_APP_API_URL}/api/tenant/set-language`, formData)

const removeNotification = ({ data }) => deleteRequest(`${REACT_APP_API_URL}/api/user/delete-notification`, data)
const addOnDepositRoll = (data, isBonus, isPromotionsRollings) =>
  postRequest(`${REACT_APP_API_URL}/api/user/${isBonus ? 'roll-bonus-wager' : isPromotionsRollings ? 'roll-promotion-cash-wagering' : 'roll-deposit-addon'}`, data)
const deleteAllNotification = (data) =>
  deleteRequest(`${REACT_APP_API_URL}/api/user/delete-all-notification`, data)
const markAllNotification = (data) =>
  putRequest(`${REACT_APP_API_URL}/api/user/read-all-notification`, data)
const updateAnonymityStatus = (isAnonymousFlag) => putRequest(`${REACT_APP_API_URL}/api/user/update-user-anonymous-status`, isAnonymousFlag)

export {
  getDocuments,
  getDocumentsLabel,
  getUserDetails,
  removeProfileImage,
  updateDocuments,
  updateUserDetails,
  uploadProfileImage,
  getSumSubAccessToken,
  getAllReviews,
  getCashbackBalance,
  getWalletAmount,
  getAllCurrencies,
  getReferredUsersList,
  getNotifications,
  readNotification,
  getBonusTickets,
  rollBonus,
  getRanks,
  getSeasonRanks,
  addUserToRollingContest,
  getBonusWalletProgress,
  updateUserLanguage,
  removeNotification,
  getUniqueCodes,
  getAddOnConfigDetails,
  addOnDepositRoll,
  getAddOnConfigBonusDetails,
  deleteAllNotification,
  markAllNotification,
  updateAnonymityStatus,
  addBrowserId
}
