import { getRequest, postRequest } from '../axios'

const { REACT_APP_API_URL } = process.env
const getGiftCards = ({ limit, pageNo, search }) =>
  getRequest(`${REACT_APP_API_URL}/api/voucher/get-gift-voucher?limit=${limit}&pageNo=${pageNo}&search=${search}`)
const getVouchers = ({ limit, pageNo, search }) =>
  getRequest(`${REACT_APP_API_URL}/api/voucher/get-voucher?limit=${limit}&pageNo=${pageNo}&search=${search}`)
const redeemGiftCard = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/voucher/redeem-gift-voucher`, data)
const redeemVoucher = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/voucher/redeem-voucher`, data)
const activePauseGiftCard = ({ formData }) =>
  postRequest(`${REACT_APP_API_URL}/api/voucher/active-gift-voucher`, formData)
const getGiftCardProgress = ({ voucherId }) =>
  getRequest(`${REACT_APP_API_URL}/api/voucher/voucher-progress-bar?voucherId=${voucherId}`)

export {
  getGiftCards,
  getVouchers,
  redeemGiftCard,
  redeemVoucher,
  activePauseGiftCard,
  getGiftCardProgress
}
