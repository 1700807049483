import { takeLatest, put, select } from 'redux-saga/effects'
import { toast } from '../../components/Toast'
import { t } from 'i18next'
import {
  getGeneralBonus,
  getRackbackBonus,
  getPaybackBonus,
  getDashboardBonus,
  claimRackbackBonus,
  claimPaybackBonus,
  getRollOverType,
  getRollOverData,
  getUserBonusTransaction,
  getUserBonusCount,
  claimGeneralBonus,
  claimSportsPaybackBonus,
  getSportsPaybackBonus,
  getPromotionConfigs
} from '../../utils/apiCalls/bonus'

import {
  getPromotionConfigsStart,
  getPromotionConfigsSuccess,
  getPromotionConfigsFailure,
  getUserBonusCountStart,
  getUserBonusCountSuccess,
  getUserBonusCountFailure,
  getUserBonusTransactionStart,
  getUserBonusTransactionSuccess,
  getUserBonusTransactionFailure,
  getRollOverTypeStart,
  getRollOverTypeSuccess,
  getRollOverTypeFailure,
  getRollOverDataStart,
  getRollOverDataSuccess,
  getRollOverDataFailure,
  getGeneralBonusStart,
  getGeneralBonusSuccess,
  getGeneralBonusFailure,
  getRackbackBonusStart,
  getRackbackBonusSuccess,
  getRackbackBonusFailure,
  getPaybackBonusStart,
  getPaybackBonusSuccess,
  getPaybackBonusFailure,
  getDashboardBonusStart,
  getDashboardBonusSuccess,
  getDashboardBonusFailure,
  claimRackbackBonusStart,
  claimRackbackBonusComplete,
  claimPaybackBonusStart,
  claimPaybackBonusComplete,
  claimGeneralBonusStart,
  claimGeneralBonusComplete,
  getSportsPaybackBonusStart,
  getSportsPaybackBonusSuccess,
  getSportsPaybackBonusFailure,
  getSportPaybackClaimBonusStart,
  getSportPaybackClaimBonusSuccess
} from '../redux-slices/bonus'

export default function * bonusWatcher () {
  yield takeLatest(getPromotionConfigsStart.type, getPromotionConfigsWorker)
  yield takeLatest(getUserBonusCountStart.type, getUserBonusCountWorker)
  yield takeLatest(getUserBonusTransactionStart.type, getUserBonusTransactionWorker)
  yield takeLatest(getRollOverTypeStart.type, getRollOverTypeWorker)
  yield takeLatest(getRollOverDataStart.type, getRollOverDataWorker)
  yield takeLatest(claimGeneralBonusStart.type, claimGeneralBonusWorker)
  yield takeLatest(claimPaybackBonusStart.type, claimPaybackBonusWorker)
  yield takeLatest(claimRackbackBonusStart.type, claimRackbackBonusWorker)
  yield takeLatest(getGeneralBonusStart.type, getGeneralBonusWorker)
  yield takeLatest(getRackbackBonusStart.type, getRackbackBonusWorker)
  yield takeLatest(getPaybackBonusStart.type, getPaybackBonusWorker)
  yield takeLatest(getDashboardBonusStart.type, getDashboardBonusWorker)
  yield takeLatest(getSportPaybackClaimBonusStart.type, claimSportsPaybackBonusWorker)
  yield takeLatest(getSportsPaybackBonusStart.type, getSportsPaybackBonusWorker)
}
function * claimPaybackBonusWorker (action) {
  try {
    const { onSuccess, formData } = action.payload
    const res = yield claimPaybackBonus(formData)
    if (res.status === 200) {
      yield put(claimPaybackBonusComplete())
      yield onSuccess()
      yield toast(t('claimSuccess'), 'success')
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(claimPaybackBonusComplete())
  }
}
function * claimGeneralBonusWorker (action) {
  try {
    const { onSuccess, formData } = action.payload
    const res = yield claimGeneralBonus(formData)
    if (res.status === 200) {
      yield put(claimGeneralBonusComplete())
      yield onSuccess()
      yield toast(t('claimSuccess'), 'success')
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(claimGeneralBonusComplete())
  }
}
function * claimRackbackBonusWorker (action) {
  try {
    const { onSuccess } = action.payload
    const res = yield claimRackbackBonus()
    if (res.status === 200) {
      yield put(claimRackbackBonusComplete())
      yield onSuccess()
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(claimRackbackBonusComplete())
  }
}
function * getPromotionConfigsWorker (action) {
  try {
    const { data } = yield getPromotionConfigs()
    yield put(getPromotionConfigsSuccess(data?.data?.depositConfigs))
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getPromotionConfigsFailure())
  }
}
function * getRollOverTypeWorker (action) {
  try {
    const { data } = yield getRollOverType()
    yield put(getRollOverTypeSuccess(data?.data?.rollingTypes))
  } catch (e) {
    yield put(getRollOverTypeFailure())
  }
}
function * getRollOverDataWorker (action) {
  try {
    const { rollOverData } = yield select((state) => state?.bonus)
    const { formData } = action.payload
    const { data } = yield getRollOverData(formData)
    let tempData
    if (formData?.page === 1) {
      tempData = data?.data?.data
    } else {
      tempData = {
        ...rollOverData,
        rows: [...rollOverData?.rows, ...data?.data?.data?.rows]
      }
    }
    yield put(getRollOverDataSuccess(tempData))
  } catch (e) {
    yield put(getRollOverDataFailure())
  }
}
function * getUserBonusCountWorker (action) {
  try {
    const { data } = yield getUserBonusCount()
    yield put(getUserBonusCountSuccess(data?.data?.userBonusCount?.[0]))
  } catch (e) {
    yield put(getUserBonusCountFailure())
  }
}
function * getUserBonusTransactionWorker (action) {
  try {
    const { userBonusTransaction } = yield select((state) => state?.bonus)
    const { formData } = action.payload
    const { data } = yield getUserBonusTransaction(formData)
    let tempData
    if (formData?.page === 1) {
      tempData = data?.data?.userBonusTransactionService
    } else {
      tempData = {
        ...userBonusTransaction,
        rows: [...userBonusTransaction?.rows, ...data?.data?.userBonusTransactionService?.rows]
      }
    }
    yield put(getUserBonusTransactionSuccess(tempData))
  } catch (e) {
    yield put(getUserBonusTransactionFailure())
  }
}
function * getGeneralBonusWorker (action) {
  try {
    const { data } = yield getGeneralBonus()
    yield put(getGeneralBonusSuccess(data?.data))
  } catch (e) {
    yield put(getGeneralBonusFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getRackbackBonusWorker (action) {
  try {
    const { data } = yield getRackbackBonus()
    yield put(getRackbackBonusSuccess(data?.data))
  } catch (e) {
    yield put(getRackbackBonusFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * getPaybackBonusWorker (action) {
  try {
    const { data } = yield getPaybackBonus()
    yield put(getPaybackBonusSuccess(data?.data))
  } catch (e) {
    yield put(getPaybackBonusFailure(e?.response?.data?.errors[0]?.description))
  }
}
function * getDashboardBonusWorker (action) {
  try {
    const { data } = yield getDashboardBonus()
    yield put(getDashboardBonusSuccess(data?.data))
  } catch (e) {
    yield put(getDashboardBonusFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * getSportsPaybackBonusWorker (action) {
  try {
    const { data } = yield getSportsPaybackBonus()
    yield put(getSportsPaybackBonusSuccess(data?.data))
  } catch (e) {
    yield put(getSportsPaybackBonusFailure(e?.response?.data?.errors[0]?.description))
  }
}

function * claimSportsPaybackBonusWorker (action) {
  try {
    const { onSuccess, formData } = action.payload
    const res = yield claimSportsPaybackBonus(formData)
    if (res.status === 200) {
      yield put(getSportPaybackClaimBonusSuccess())
      yield onSuccess()
      yield toast(t('claimSuccess'), 'success')
    }
  } catch (e) {
    yield toast(e?.response?.data?.errors[0]?.description, 'error')
    yield put(getSportPaybackClaimBonusSuccess())
  }
}
