import { getRequest, postRequest } from '../axios'
const { REACT_APP_API_URL } = process.env
const getGeneralBonus = (formData) => {
  // const urlString = new URLSearchParams(formData).toString()
  return getRequest(`${REACT_APP_API_URL}/api/user/general-bonus`)
}
const getRackbackBonus = (formData) => {
  // const urlString = new URLSearchParams(formData).toString()
  return getRequest(`${REACT_APP_API_URL}/api/user/rackback-stack`)
}
const getPaybackBonus = (formData) => {
  return getRequest(`${REACT_APP_API_URL}/api/user/payback-bonus`)
}
const getDashboardBonus = (formData) => {
  // const urlString = new URLSearchParams(formData).toString()
  return getRequest(`${REACT_APP_API_URL}/api/user/bonus-dashboard`)
}
const claimRackbackBonus = (formData) => {
  // const urlString = new URLSearchParams(formData).toString()
  return postRequest(`${REACT_APP_API_URL}/api/user/distribute-rackback-stack`)
}
const claimPaybackBonus = (formData) => {
  // const urlString = new URLSearchParams(formData).toString()
  return postRequest(`${REACT_APP_API_URL}/api/user/claim-payback-bonus`, formData)
}
const getRollOverType = () => {
  return getRequest(`${REACT_APP_API_URL}/api/user/lock-funds-dropdown`)
}
const getRollOverData = (formData) => {
  const urlString = new URLSearchParams(formData).toString()
  return getRequest(`${REACT_APP_API_URL}/api/user/lock-funds-rolling?${urlString}`)
}
const getUserBonusTransaction = (formData) => {
  const urlString = new URLSearchParams(formData).toString()
  return getRequest(`${REACT_APP_API_URL}/api/user/user-bonus-transaction?${urlString}`)
}
const getUserBonusCount = (formData) => {
  return getRequest(`${REACT_APP_API_URL}/api/user/user-bonus-count`)
}
const claimGeneralBonus = (formData) => {
  // const urlString = new URLSearchParams(formData).toString()
  return postRequest(`${REACT_APP_API_URL}/api/user/claim-general-bonus`, formData)
}
const claimSportsPaybackBonus = (formData) => {
  return postRequest(`${REACT_APP_API_URL}/api/user/claim-sports-payback-bonus`, formData)
}
const getSportsPaybackBonus = (formData) => {
  return getRequest(`${REACT_APP_API_URL}/api/user/sports-payback-bonus`)
}

const getPromotionConfigs = () => getRequest(`${REACT_APP_API_URL}/api/user/promotion-deposit-configs`)

export {
  getGeneralBonus,
  getRackbackBonus,
  getPaybackBonus,
  getDashboardBonus,
  claimRackbackBonus,
  claimPaybackBonus,
  getRollOverType,
  getRollOverData,
  getUserBonusTransaction,
  getUserBonusCount,
  claimGeneralBonus,
  claimSportsPaybackBonus,
  getSportsPaybackBonus,
  getPromotionConfigs
}
