import { getRequest } from '../axios'
const { REACT_APP_API_URL } = process.env

const getMyBets = ({ page, pageSize, from, to, isV2User, masterCasinoGameId }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/my-bets?page=${page}&pageSize=${pageSize}&from=${from}&to=${to}&isV2User=${isV2User || ''}&masterCasinoGameId=${masterCasinoGameId || ''}`)

const getAllBets = ({ page, pageSize, from, to, isV2User, masterCasinoGameId }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/all-bets?page=${page}&pageSize=${pageSize}&from=${from}&to=${to}&isV2User=${isV2User || ''}&masterCasinoGameId=${masterCasinoGameId || ''}`)

const getTenantLeaderBoard = ({ page, pageSize, from, to, isV2User, masterCasinoGameId }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-tenant-leaderboard-new?page=${page}&from=${from}&to=${to}&pageSize=${pageSize}&isV2User=${isV2User || ''}&masterCasinoGameId=${masterCasinoGameId || ''}`)
const getRollingContestList = ({
  limit,
  pageNo
}) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/rolling-competition?limit=${limit}&pageNo=${pageNo}`)

const getRollLeaderBoard = ({
  page,
  pageSize,
  from,
  to,
  masterCasinoGameId
}) =>
  getRequest(`${REACT_APP_API_URL}/api/user/competition-leaderboard?pageNo=${page}&limit=${pageSize}&from=${from}&to=${to}&masterCasinoGameId=${masterCasinoGameId || ''}`)

const getEvents = ({
  limit,
  pageNo
}) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/events?limit=${limit}&pageNo=${pageNo}`)

export {
  getEvents,
  getRollLeaderBoard,
  getRollingContestList,
  getMyBets,
  getAllBets,
  getTenantLeaderBoard
}
