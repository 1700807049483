import { getRequest } from '../axios'

const { REACT_APP_API_URL } = process.env
const getLanguageData = ({ manual }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-language-support-keys?manual=${manual}`)
const getLanguages = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-languages`)

export {
  getLanguageData,
  getLanguages
}
