import { getRequest } from '../axios'

const { REACT_APP_API_URL } = process.env
const getCmsPage = ({ cmsPageId }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-cms-page?cmsPageId=${cmsPageId}`)
const getTenantBannerList = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/banner-list`)
const getTenantCmsFooter = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-cms-footer`)
const getTenantConfigurations = () =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-tenant-configurations`)

export {
  getCmsPage,
  getTenantBannerList,
  getTenantCmsFooter,
  getTenantConfigurations
}
