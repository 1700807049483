import { getRequest, postRequest, deleteRequest } from '../axios'
import { getDeviceType } from '../getDeviceType'

const { REACT_APP_API_URL } = process.env
const getGameCategory = () =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-casino-category-listing`)
const getGameSubCategory = ({ categoryId }) =>
  getRequest(`${REACT_APP_API_URL}/api/tenant/get-tenant-game-sub-category?categoryId=${categoryId}&deviceType=${getDeviceType()}`)
const getCasinoGames = ({ page, pageSize, sortField, sortOrder, category, searchKey, userID, masterCasinoProviderId, recommended }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-all-casino-game?page=${page}&pageSize=${pageSize}&sortField=${sortField}&sortOrder=${sortOrder}&category=${category}&searchKey=${searchKey || ''}&userID=${userID}&masterCasinoProviderId=${masterCasinoProviderId || ''}&recommended=${recommended || false}`)
const getProviders = ({ categoryId, showCount }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-all-provider?categoryId=${categoryId || ''}&count=${showCount}`)
const addFavoriteGame = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/user/add-favorite-game`, data)
const removeFavoriteGame = (data) =>
  deleteRequest(`${REACT_APP_API_URL}/api/user/remove-favorite-game`, data)

const getFavoriteGame = ({ limit, pageNo }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-favorite-games?limit=${limit}&pageNo=${pageNo}`)
const getTopGames = ({ limit }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-top-games?limit=${limit}`)
const getTopWinners = ({ limit }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-top-winners?limit=${limit}`)
const getCurrentWinners = ({ limit }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-current-winners?limit=${limit}`)
const getCasinoProvider = ({ category = '', search }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/get-all-category-providers?category=${category}${search ? `&search=${search}` : ''}`)
const playGame = (data) =>
  postRequest(`${REACT_APP_API_URL}/api/user/launch-game`, data)
const getHotGames = ({
  gameName,
  limit,
  pageNo,
  sortOrder,
  masterCasinoProviderId
}) => {
  return getRequest(`${REACT_APP_API_URL}/api/user/hot-games?gameName=${gameName}&limit=${limit}&pageNo=${pageNo}&sortOrder=${sortOrder || ''}&masterCasinoProviderId=${masterCasinoProviderId?.length > 0 ? masterCasinoProviderId : ''}`)
}
const getNewGames = ({ gameName, limit, pageNo }) =>
  getRequest(`${REACT_APP_API_URL}/api/user/new-games?gameName=${gameName}&limit=${limit}&pageNo=${pageNo}`)
const getTopSlotsGames = () => {
  return getRequest(`${REACT_APP_API_URL}/api/user/get-all-casino-game-home`)
}
const getNewGamesLobby = () =>
  getRequest(`${REACT_APP_API_URL}/api/user/new-games-lobby`)
const getHotGamesLobby = () =>
  getRequest(`${REACT_APP_API_URL}/api/user/hot-games-lobby`)

export {
  getGameCategory,
  getGameSubCategory,
  getCasinoGames,
  getProviders,
  addFavoriteGame,
  removeFavoriteGame,
  getFavoriteGame,
  getTopGames,
  getTopWinners,
  getCurrentWinners,
  getCasinoProvider,
  playGame,
  getHotGames,
  getNewGames,
  getTopSlotsGames,
  getNewGamesLobby,
  getHotGamesLobby
}
